/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { LockIcon } from "@chakra-ui/icons";
import { Avatar, Box, Button, Flex, FormLabel, Icon, Select, SimpleGrid, Text, useColorModeValue, border, Divider, StatGroup } from "@chakra-ui/react";
// Assets
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
const tableData = [
  [
    { name: "Upper Limb", noOfSessions: "9", totalTimeSpent: "10.8 Hr", avgTime: 1.2 },
    { name: "Lower Limb", noOfSessions: "8", totalTimeSpent: "10.4 Hr", avgTime: 1.3 },
    { name: "Head & Neck", noOfSessions: "10", totalTimeSpent: "12.0 Hr", avgTime: 1.2 },
    { name: "Throat", noOfSessions: "7", totalTimeSpent: "7.7 Hr", avgTime: 1.1 },
    { name: "Abdomen", noOfSessions: "8", totalTimeSpent: "8.6 Hr", avgTime: 1.08 },
    { name: "Shoulder Joint", noOfSessions: "7", totalTimeSpent: "8.4 Hr", avgTime: 1.2 },
    { name: "Knee Joint", noOfSessions: "5", totalTimeSpent: "5.8 Hr", avgTime: 1.16 },
    { name: "Cubital Fossa", noOfSessions: "6", totalTimeSpent: "6.6 Hr", avgTime: 1.1 },
    { name: "Skeletal System", noOfSessions: "11", totalTimeSpent: "12.4 Hr", avgTime: 1.13 },
    { name: "Respiratory System", noOfSessions: "7", totalTimeSpent: "8.2 Hr", avgTime: 1.17 },
  ],
  [
    { name: "Upper Limb", noOfSessions: "18", totalTimeSpent: "19.08 Hr", avgTime: 1.06 },
    { name: "Lower Limb", noOfSessions: "20", totalTimeSpent: "19.2 Hr", avgTime: 0.96 },
    { name: "Head & Neck", noOfSessions: "18", totalTimeSpent: "19.8 Hr", avgTime: 1.1 },
    { name: "Throat", noOfSessions: "19", totalTimeSpent: "26.6 Hr", avgTime: 1.4 },
    { name: "Abdomen", noOfSessions: "21", totalTimeSpent: "22.68 Hr", avgTime: 1.08 },
    { name: "Shoulder Joint", noOfSessions: "26", totalTimeSpent: "33.02 Hr", avgTime: 1.27 },
    { name: "Knee Joint", noOfSessions: "17", totalTimeSpent: "27.37 Hr", avgTime: 1.61 },
    { name: "Cubital Fossa", noOfSessions: "18", totalTimeSpent: "21.24 Hr", avgTime: 1.18 },
    { name: "Skeletal System", noOfSessions: "23", totalTimeSpent: "35.19 Hr", avgTime: 1.53 },
    { name: "Respiratory System", noOfSessions: "19", totalTimeSpent: "31.73 Hr", avgTime: 1.67 },
  ],
  [
    { name: "Upper Limb", noOfSessions: "38", totalTimeSpent: "19.08 Hr", avgTime: 1.06 },
    { name: "Lower Limb", noOfSessions: "30", totalTimeSpent: "19.2 Hr", avgTime: 0.96 },
    { name: "Head & Neck", noOfSessions: "38", totalTimeSpent: "19.8 Hr", avgTime: 1.1 },
    { name: "Throat", noOfSessions: "39", totalTimeSpent: "26.6 Hr", avgTime: 1.4 },
    { name: "Abdomen", noOfSessions: "41", totalTimeSpent: "22.68 Hr", avgTime: 1.08 },
    { name: "Shoulder Joint", noOfSessions: "36", totalTimeSpent: "33.02 Hr", avgTime: 1.27 },
    { name: "Knee Joint", noOfSessions: "37", totalTimeSpent: "27.37 Hr", avgTime: 1.61 },
    { name: "Cubital Fossa", noOfSessions: "38", totalTimeSpent: "21.24 Hr", avgTime: 1.18 },
    { name: "Skeletal System", noOfSessions: "33", totalTimeSpent: "35.19 Hr", avgTime: 1.53 },
    { name: "Respiratory System", noOfSessions: "39", totalTimeSpent: "31.73 Hr", avgTime: 1.67 },
  ],
  [
    { name: "Upper Limb", noOfSessions: "68", totalTimeSpent: "19.08 Hr", avgTime: 1.61 },
    { name: "Lower Limb", noOfSessions: "60", totalTimeSpent: "19.2 Hr", avgTime: 1.16 },
    { name: "Head & Neck", noOfSessions: "68", totalTimeSpent: "19.8 Hr", avgTime: 1.12 },
    { name: "Throat", noOfSessions: "69", totalTimeSpent: "26.6 Hr", avgTime: 1.42 },
    { name: "Abdomen", noOfSessions: "61", totalTimeSpent: "22.68 Hr", avgTime: 1.48 },
    { name: "Shoulder Joint", noOfSessions: "66", totalTimeSpent: "33.02 Hr", avgTime: 1.17 },
    { name: "Knee Joint", noOfSessions: "67", totalTimeSpent: "27.37 Hr", avgTime: 1.01 },
    { name: "Cubital Fossa", noOfSessions: "78", totalTimeSpent: "21.24 Hr", avgTime: 1.08 },
    { name: "Skeletal System", noOfSessions: "73", totalTimeSpent: "35.19 Hr", avgTime: 1.03 },
    { name: "Respiratory System", noOfSessions: "79", totalTimeSpent: "31.73 Hr", avgTime: 1.27 },
  ],
  [
    { name: "Upper Limb", noOfSessions: "88", totalTimeSpent: "19.08 Hr", avgTime: 1.16 },
    { name: "Lower Limb", noOfSessions: "88", totalTimeSpent: "19.2 Hr", avgTime: 1.26 },
    { name: "Head & Neck", noOfSessions: "85", totalTimeSpent: "19.8 Hr", avgTime: 1.32 },
    { name: "Throat", noOfSessions: "95", totalTimeSpent: "26.6 Hr", avgTime: 1.72 },
    { name: "Abdomen", noOfSessions: "81", totalTimeSpent: "22.68 Hr", avgTime: 1.18 },
    { name: "Shoulder Joint", noOfSessions: "86", totalTimeSpent: "33.02 Hr", avgTime: 1.07 },
    { name: "Knee Joint", noOfSessions: "97", totalTimeSpent: "27.37 Hr", avgTime: 1.31 },
    { name: "Cubital Fossa", noOfSessions: "98", totalTimeSpent: "21.24 Hr", avgTime: 1.55 },
    { name: "Skeletal System", noOfSessions: "93", totalTimeSpent: "35.19 Hr", avgTime: 1.34 },
    { name: "Respiratory System", noOfSessions: "99", totalTimeSpent: "31.73 Hr", avgTime: 1.39 },
  ],
];

export default function UserReports() {
  // Chakra Color Mode
  const [filterIndex, setFilterIndex] = useState(0);
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondarywhite", "whiteAlpha.100");

  return (
    <Box pt={{ base: "150px", md: "115px", xl: "115px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap="20px" mb="20px">
        <Card direction="column" w="100%" minH="200px" boxShadow="lg">
          <Flex justify="center" direction="column" align="center" px="20px" py="15px" h="100%" >
            <Text fontSize="xl" fontWeight="semibold" textAlign="center" mb="4" >
              Virtual & Mixed Reality
            </Text>

            <Flex w="100%" maxW="600px" direction="column" spacing={4}>
              {[
                [
                  { label: "No Of Licenses:", value: "20" },
                  { label: "Total Sessions", value: "535" },
                ],
                [
                  { label: "Avg Session Per Device", value: "18 Mins" },
                  { label: "Avg Session Duration", value: "40 Mins" },
                ],
                [
                  { label: "Max Session For A Device", value: "54 Mins" },
                  { label: "Min Session For A Device", value: "3 Mins" },
                ],
              ].map((row, rowIndex) => (
                <Flex justify="space-between" mb="4" width="100%" gap={4} key={rowIndex}>
                  {row.map(({ label, value }, index) => (
                    <Flex key={index} direction="column" justifyContent="center" alignItems="center" flex="1">
                      <Text fontSize="md" textAlign="center" mb={2}>
                        {label}
                      </Text>
                      <Box display="flex" alignItems="center" justifyContent="center" bg="brand.500" h="50px" minW="70px" px={2} borderRadius="5px" color="white" fontWeight="bold" textAlign="center" whiteSpace="nowrap">
                        {value}
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              ))}
            </Flex>

            <Box mt={5} p={4} bg="brand.500" borderRadius="md">
              <Text fontSize="md" color="white">
                <b>Note:</b> For optimal use, ensure usage of devices is distributed equally.
              </Text>
            </Box>
          </Flex>
        </Card>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <Tasks /> */}

        <Card direction="column" w="100%" minh="200px" boxShadow="lg">
          <Text  mb={3} fontSize="xl" fontWeight="600" mt="4px">
            Device Usage : No of sessions
          </Text>
          <Divider />
          <Flex justify="center" align="center" px="10px" pt="5px" h={"100%"}>
            <Card display="flex" flexDirection="row" overflowX={"scroll"}>
              {Array.from({ length: 20 }, (_, i) => `Device ${i + 1}`).map((item, index) => {
                return (
                  <Card minW="150px" textAlign="center">
                    <Box alignContent={"center"} backgroundColor="brand.500" h="45px" w="45px" className="mx-auto" mb={"2"} borderRadius={"5px"}>
                      <Text fontWeight={"bold"} color={"white"}>
                        {Math.floor(Math.random() * 20) + 1}
                      </Text>
                    </Box>
                    <Text>{item}</Text>
                  </Card>
                );
              })}
            </Card>
          </Flex>
        </Card>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <Tasks /> */}

        <Card direction="column" w="100%" minH="200px" boxShadow="lg">
          <Text  mb={3} fontSize="xl" fontWeight="600" mt="4px">
            Modules Analysis
          </Text>
          <Divider />
          <Box mt={5}>
            <Box display={"flex"} justifyContent={"end"} gap={3}>
              {["14D", "1M", "3M", "6M", "1Y"].map((item, index) => {
                return (
                  <Box padding={2} backgroundColor={filterIndex == index && "brand.500"} w={"45px"} textAlign={"center"} border={"1px solid brand.500"} cursor={filterIndex == index ? "" : "pointer"} onClick={() => filterIndex != index && setFilterIndex(index)} color={filterIndex == index ? "white" : "brand.500"} borderRadius={8}>
                    {item}
                  </Box>
                );
              })}
            </Box>

            <TableContainer mt={5} borderCollapse="separate" borderRadius={5} overflow="hidden">
              <Table borderCollapse="separate">
                <Thead>
                  <Tr>
                    <Th border="1px solid black" textAlign={"center"}>
                      Name
                    </Th>
                    <Th border="1px solid black" textAlign={"center"}>
                      No Of Sessions
                    </Th>
                    <Th border="1px solid black" textAlign={"center"}>
                      Total Time Spent
                    </Th>
                    <Th border="1px solid black" textAlign={"center"}>
                      Avg time Per session
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData[filterIndex]?.map((row, index) => (
                    <Tr key={index}>
                      <Td border="1px solid black" fontWeight={"bold"}>
                        {row.name}
                      </Td>
                      <Td border="1px solid black" textAlign={"center"}>
                        {row.noOfSessions}
                      </Td>
                      <Td border="1px solid black" textAlign={"center"}>
                        {(row.noOfSessions * row.avgTime).toFixed(1)} Hr
                      </Td>
                      <Td border="1px solid black" textAlign={"center"}>
                        {row.avgTime}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px'> */}
        <DailyTraffic boxShadow="lg" />
        <PieCard boxShadow="lg" />
        {/* </SimpleGrid> */}
      </SimpleGrid>
    </Box>
  );
}
