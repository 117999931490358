import React, { useEffect, useState } from "react";

// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import BarChart from "components/charts/BarChart";

// Custom components
import Card from "components/card/Card.js";
import {
  barChartDataDailyTraffic,
  barChartOptionsDailyTraffic,
} from "variables/charts";

// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import { API_SERVER } from "config/constant";

export default function DailyTraffic(props) {
  const { ...rest } = props;
  const [chartData, setChartData] = useState(barChartDataDailyTraffic);
  const [chartOptions, setChartOptions] = useState(barChartOptionsDailyTraffic);

  const getDailyOnlineUsersData = async () => {
    try {
      const response = await fetch(`${API_SERVER}/dailyactiveusers`,{
      method:"GET",  
      headers: {
          'Content-Type': 'application/json',
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,        
        },
      });
      // Check for successful response status
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error; // Re-throw the error for further handling
    }
  };
  
  // useEffect(()=>{
  //   setChartData(barChartDataDailyTraffic);
  //   setChartOptions(barChartOptionsDailyTraffic);
  // },[getDailyOnlineUsersData]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDailyOnlineUsersData();

        setChartData((prev) => {
          return [{ ...prev[0], data: data.online_users }, ...prev.slice(1)];
        });
        // chartData[0].data = data.online_users;
        setChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              ...prev.xaxis,
              categories: [...prev.xaxis.categories,...data.activity_date],
            },
          };
        });
        // chartOptions.xaxis.chartOptions = data.activity_date
      } catch (error) {
        // Handle errors appropriately, e.g., display an error message
        console.error('Error fetching users:', error);
      }
    };
  
    fetchData();
  }, []);

  
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      <Flex justify='space-between' align='start' px='10px' pt='5px'>
        <Flex flexDirection='column' align='start' me='20px'>
          <Flex w='100%'>
            <Text
              me='auto'
              color='Gray.800'
              fontSize='md'
              fontWeight='500'>
              Daily Traffic
            </Text>
          </Flex>
          <Flex align='end'>
            
          </Flex>
        </Flex>
        {/* <Flex align='center'>
          <Icon as={RiArrowUpSFill} color='green.500' />
          <Text color='green.500' fontSize='sm' fontWeight='700'>
            +2.45%
          </Text>
        </Flex> */}
      </Flex>
      <Box h='240px' mt='auto'>
        <BarChart
          chartData={chartData}
          chartOptions={chartOptions}
        />
      </Box>
    </Card>
  );
}
