/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { LockIcon } from "@chakra-ui/icons";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { Avatar, Box, Button, Flex, FormLabel, Icon, Select, SimpleGrid, Text, useColorModeValue, border, Divider, StatGroup } from "@chakra-ui/react";
// Assets
import Card from "components/card/Card";

import { StatsIcon } from "components/icons/Icons";
import { API_SERVER } from "config/constant";
import React, { useEffect, useState } from "react";

import { IoCallSharp, IoVideocam, IoVideocamSharp, IoWatch } from "react-icons/io5";
import { NavLink } from "react-router-dom/cjs/react-router-dom";


export default function UserReports() {
  // Chakra Color Mode
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalOnlineUsers, setTotalOnlineUsers] = useState(0);
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const getOnlineStatus = async () => {
    try {
      const response = await fetch(`${API_SERVER}/userstatuscount`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
        },
      });
      // Check for successful response status
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error; // Re-throw the error for further handling
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOnlineStatus();
        setTotalOnlineUsers(data[0].online_users_count);
        setTotalUsers(data[0].total_users_count);
      } catch (error) {
        // Handle errors appropriately, e.g., display an error message
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minH={"90%"} minW={"90%"}>
          <ModalCloseButton />
          <ModalBody>
            <div style={{display:"flex",height:"80vh",width:"100%",marginTop:30}}>
            <div style={{width:"100%"}}>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Vxl5ShyPYuc" title="DEFTXR - 3D Human Anatomy in Mixed Reality" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
            </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box pt={{ base: "150px", md: "115px", xl: "115px" }}>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap="20px" mb="20px">
          {/* <Tasks /> */}

          <Card direction="column" w="100%" minH="220px">
            <Text color={"black"} mb={3} fontSize="xl" fontWeight="600" mt="4px">
              DEFTXR Virtual & Mixed Reality
            </Text>
            <Divider />
            <Flex justify="center" direction={"column"} align="center" px="10px" mt pt="5px" h={"100%"}>
            <Flex w="100%" maxW="600px" mt={5} direction="column" spacing={4}>
              {[
                [
                  { label: "Type Of License", value: "Perpetual" },
                  { label: "No. Of Licenses", value: "20" },
                ],
                
              ].map((row, rowIndex) => (
                <Flex justify="space-between" mb="4" width="100%" gap={4} key={rowIndex}>
                  {row.map(({ label, value }, index) => (
                    <Flex key={index}  flexDirection={"column"} justifyContent="center" alignItems="center" flex="1">
                      <Text fontSize="lg" textAlign="center" mb={2}>
                        {label}
                      </Text>
                      <Box display="flex" alignItems="center" justifyContent="center" bg="brand.500" h="50px" minW="70px" px={2} borderRadius="5px" color="white" fontWeight="bold" textAlign="center" whiteSpace="nowrap">
                        {value}
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              ))}
            </Flex>

              <NavLink to="/admin/virtual-mixed-reality">
                <Button iconSpacing={5} display={"flex"} gap={2} color={"white"} mt={6} borderRadius={5} backgroundColor={"#112274"}>
                  <StatsIcon /> View Analytics
                </Button>
              </NavLink>
            </Flex>
          </Card>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap="20px" mb="20px">
          {/* <Tasks /> */}

          <Card direction="column" w="100%" minH="220px">
            <Text color={"black"} mb={3} fontSize="xl" fontWeight="600" mt="4px">
              DEFTXR Classroom (For Teachers)
            </Text>
            <Divider />
            <Flex justify="center" direction={"column"} align="center" px="10px" pt="5px" h={"100%"}>
            <Flex w="100%" maxW="600px" mt={5} direction="column" spacing={4}>
              {[
                [
                  { label: "Type Of License", value: "Perpetual" },
                  { label: "No. Of Licenses", value: "3" },
                ],
                
              ].map((row, rowIndex) => (
                <Flex justify="space-between" mb="4" width="100%" gap={4} key={rowIndex}>
                  {row.map(({ label, value }, index) => (
                    <Flex key={index}  flexDirection={"column"} justifyContent="center" alignItems="center" flex="1">
                      <Text fontSize="lg" textAlign="center" mb={2}>
                        {label}
                      </Text>
                      <Box display="flex" alignItems="center" justifyContent="center" bg="brand.500" h="50px" minW="70px" px={2} borderRadius="5px" color="white" fontWeight="bold" textAlign="center" whiteSpace="nowrap">
                        {value}
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              ))}
            </Flex>
              <Button borderRadius={5} iconSpacing={5} display={"flex"} gap={2} color={"white"} mt={6} backgroundColor={"#112274"}>
                <StatsIcon /> View Analytics
              </Button>
            </Flex>
          </Card>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap="20px" mb="20px">
          {/* <Tasks /> */}

          <Card direction="column" w="100%" h="250px">
            <Text color={"black"} mb={3} fontSize="xl" fontWeight="600" mt="4px">
              DEFTXR Web (For Students)
            </Text>
            <Divider />
            <Flex justify="center" direction={"column"} align="center" px="10px" pt="5px" h={"100%"}>
              <Flex w={"100%"} justifyContent={"center"} alignItems={"center"} gap={1} fontSize={20}>
                <LockIcon me={1} />
                Get in touch with us
                <Text size="20px" fontWeight={"bold"} color={"gray"}></Text>
              </Flex>
              <Flex gap={3}>
                <Button borderRadius={5} iconSpacing={5} display={"flex"} gap={2} color={"white"} mt={6} backgroundColor={"#112274"}>
                  <IoCallSharp size={18} /> Click here to receive call-back
                </Button>
                <Button borderRadius={5} onClick={onOpen} iconSpacing={5} display={"flex"} gap={2} color={"white"} mt={6} backgroundColor={"#112274"}>
                  <IoVideocam size={18} /> Watch trailer
                </Button>
              </Flex>
            </Flex>
          </Card>
        </SimpleGrid>
        {/* <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }} gap="20px" mb="20px">
          <MiniStatistics startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />} />} name="No Of Devices" value={`12`} />
          <MiniStatistics startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />} />} name="Subscriptions" value="20 devices/perpetual" />
          <MiniStatistics name="Modules" value="8" /> */}
          {/* <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        /> */}
          {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        /> */}
          {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        /> */}
        {/* </SimpleGrid> */}

        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid> */}
        
        {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap="20px">

          <Card align="center" justify="center" direction="column" w="100%" h="200px">
            <Flex justify="center" direction={"column"} align="center" px="10px" pt="5px" h={"100%"}>
              <Text color={"black"} mb={3} fontSize="xl" fontWeight="600" mt="4px">
                Subscribe to teaching modules
              </Text>
              <Button iconSpacing={5} color={"white"} backgroundColor={"#112274"}>
                <LockIcon me={1} /> Unlock
              </Button>
            </Flex>
          </Card>
        </SimpleGrid>
      </SimpleGrid> */}
      </Box>
    </>
  );
}
