// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React, { useEffect, useState } from "react";
import { API_SERVER } from "config/constant";

export default function Conversion(props) {
  const { ...rest } = props;
  const [pieChartData,setPieChartData] = useState([]);
  const [Air_Pistol_10m,setAir_Pistol_10m] = useState(0);
  const [Air_Rifle_10m,setAir_Rifle_10m] = useState(0);
  const [Rapid_Fire_25m,setRapid_Fire_25m] = useState(0);
  const [difficulty_level,setDifficulty_level] = useState('amateur');
  const getMainLeaderBoard = async () => {
    try {
      const apiUrl = `${API_SERVER}/gamesplayedineachmode`;
      const requestData = {
        difficulty_level,
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,        
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error; // Re-throw the error for further handling
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMainLeaderBoard();
        setPieChartData((prev)=>{return[60.73,35.27,4]});
        setAir_Pistol_10m(data.Air_Pistol_10m.value);
        setAir_Rifle_10m(data.Air_Rifle_10m.value);
        setRapid_Fire_25m(data.Rapid_Fire_25m.value);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMainLeaderBoard();
        setPieChartData((prev)=>{return[60.73,35.27,4]});
        setAir_Pistol_10m(data.Air_Pistol_10m.value);
        setAir_Rifle_10m(data.Air_Rifle_10m.value);
        setRapid_Fire_25m(data.Rapid_Fire_25m.value);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, [difficulty_level]);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          Devices/Modules Analysis
        </Text>
        <Select marginStart={5} value={difficulty_level} onChange={(e) => setDifficulty_level(e.target.value==""?"amateur":e.target.value)} width={40}>
        <option selected value='amateur'>VR Devices</option>
        {/* <option value='semi_pro'>Web Versions</option>
        <option value='pro'>Lab</option>
        <option value='pro'>R&D</option> */}
      </Select>
      </Flex>

      <PieChart
        h='100%'
        w='100%'
        chartData={pieChartData}
        chartOptions={pieChartOptions}
      />
      <Card
        bg={cardColor}
        flexDirection='row'
        boxShadow={cardShadow}
        w='100%'
        p='15px'
        px='20px'
        mt='15px'
        mx='auto'>
        <Flex direction='column' py='5px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.800'
              fontWeight='800'
              mb='5px'>
              Upper Body
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
           60.73%
          </Text>
        </Flex>
        <VSeparator mx={{ base: "40px", xl: "40px", "2xl": "40px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.700'
              fontWeight='800'
              mb='5px'>
              Lower Body
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            35.27%
          </Text>
        </Flex>
        <VSeparator mx={{ base: "40px", xl: "40px", "2xl": "40px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#3d4f69' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.700'
              fontWeight='800'
              mb='5px'>
              Brain
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            4%
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
