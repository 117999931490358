import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdLogout,
  MdOutlineShoppingCart,
  MdLogin,
  MdAdminPanelSettings,
  MdTab,
} from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import { GiVrHeadset } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { IoEarth } from "react-icons/io5";
import { SiGoogleclassroom } from "react-icons/si";
// Admin Imports
import MainDashboard from "views/admin/default";
import VRMixedReality from "views/admin/vr";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import TxnTables from "views/admin/txnTables";
import RTL from "views/admin/rtl";

// Auth Imports
// import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
import SignUp from "views/auth/signUp/index.jsx";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Virtual & Mixed Reality",
    layout: "/admin",
    path: "/virtual-mixed-reality",
    icon: <Icon as={GiVrHeadset} width='20px' height='20px' color='inherit' />,
    component: VRMixedReality,
  },

  {
    name: "Classroom",
    layout: "/admin",
    path: "/classroom",
    icon: <Icon as={SiGoogleclassroom} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Web",
    layout: "/admin",
    path: "/web",
    icon: <Icon as={IoEarth} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },

  // {
  //   name: "Modules Analysis",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/module-analysis",
  //   component: DataTables,
  // },
  // {
  //   name: "Transaction Tables",
  //   layout: "/admin",
  //   path: "/txn-tables",
  //   icon: (
  //     <Icon
  //       as={GrTransaction }
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: TxnTables,
  //   secondary: true,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "WIP",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdTab} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    hide: true
  },
  // {
  //   name: "Sign up",
  //   layout: "/auth",
  //   path: "/sign-up",
  //   icon: (
  //     <Icon as={MdLock} width='16px' height='16px' color='inherit' />
  //   ),
  //   component: SignUp,
  //   hide: true
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdAdminPanelSettings} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export const SubRoutes = [
  {
    name: "FAQ's",
    layout: "/admin",
    path: "/faqs",
    icon: (
      <Icon as={FaQuestionCircle} width='16px' height='16px' color='inherit' />
    ),
    component: MainDashboard,
  },

  {
    name: "Contact Us",
    layout: "/admin",
    path: "/contact-us",
    icon: (
      <Icon as={IoCallOutline} width='16px' height='16px' color='inherit' />
    ),
    component: MainDashboard,
  }
];
export const Logout = [
  {
    name: "Log Out",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdLogout} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
  }
];
export default routes;
